<template>
  <div class="container">
    <div class="screen">
      <div class="input">
        <div>区域搜索：</div>
        <el-input v-model="input" clearable placeholder="输入您要搜索的区域"></el-input>
      </div>
  
      <el-button type="primary" @click="getAreaList('nopage')">查询</el-button>
    </div>
    <div class="pagination">
      <el-pagination background @current-change="curretnChange"
            @prev-click="curretnChange"
            :current-page="page"
            :page-sizes="[100, 200, 300, 400]"
            layout="total,sizes, prev, pager, next,jumper"
              @size-change="handleSizeChange"
            :hide-on-single-page="false"
            @next-click="curretnChange"  :total="total"></el-pagination>
    </div>
    <div class="table">

      <el-table border :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center'}" :data="areaList" stripe style="width: 100%">
        <el-table-column type="index" label="编号"></el-table-column>
             <el-table-column prop="province" label="省"></el-table-column>
        <el-table-column prop="city" label="市"></el-table-column>
        <el-table-column prop="area" label="区"></el-table-column>
        <el-table-column prop="network_name" label="网点名称"></el-table-column>
        <el-table-column prop="belonging_network" label="所属网点名称"></el-table-column>
        <el-table-column prop="belonging_management" label="所属管理区"></el-table-column>
   
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background @current-change="curretnChange"
            @prev-click="curretnChange"
            :current-page="page"
            :page-sizes="[100, 200, 300, 400]"
            layout="total,sizes, prev, pager, next,jumper"
              @size-change="handleSizeChange"
            :hide-on-single-page="false"
            @next-click="curretnChange"  :total="total"></el-pagination>
    </div>
   
  </div>
</template>

<script>
export default {
  name: 'message',
  data () {
    return {
      input: '',
      areaList: [],
      total: 0,
     
      page: 1,
      limit: 100,
 

    }
  },
  created () {
    this.getAreaList()
  },
  computed: {
    shopInfo () {
      return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
    }
  },
  methods: {
    handleSizeChange (e) {
      this.limit = e
      this.getAreaList()
    },

    curretnChange (e) { // 改变页码
      this.page = e
      this.getAreaList()
    },

    getAreaList (e) { // 获取消息列表
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      // eslint-disable-next-line camelcase
      var keyword = this.input
   
      this.page = e === 'nopage' ? 1 : this.page
      var page = this.page
      var limit = this.limit
      // eslint-disable-next-line camelcase

      this.$http2.post('/merapi/express/stop_express_list', {  keyword,  limit, page,  }).then(res => {
        this.areaList = res.data.data.list
        this.total = res.data.data.total
      }).catch(err => {
        return this.$message.error(err)
      })
    }
  }
}
</script>

<style scoped lang="less">
.container {
  .screen {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-size: 19px;
    &>div {
      margin-right: 40px;
    }
    .input {
      display: flex;
      align-items: center;

      div {
        width: 100px;
      }
      .el-input {
        width: 200px;
      }
    }
  }
  .table {
    margin-top: 30px;
  }
 .el-dialog  {
   .el-dialog__header {
    display: flex;
    justify-content: center;
}
    .content {
      text-align: center;
        .title {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .message {
        font-size: 20px;
      }
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
  }
}
</style>
